import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Import components lazily
const LoginLazy = React.lazy(() => import("./Pages/Login/Login"));
const SignupLazy = React.lazy(() => import("./Pages/Signup/Signup"));
const Admin_AddProductLazy = React.lazy(() =>
  import("./Admin/Admin_Pages/Admin_AddProduct/Admin_AddProduct")
);
const Admin_MyStoreLazy = React.lazy(() =>
  import("./Admin/Admin_Pages/Admin_MyStore/Admin_MyStore")
);
const Admin_EditProductLazy = React.lazy(() =>
  import("./Admin/Admin_Pages/Admin_EditProduct/Admin_EditProduct")
);
const Admin_DashboardLazy = React.lazy(() =>
  import("./Admin/Admin_Pages/Admin_Dashboard/Admin_Dashboard")
);
const Admin_manageOrders = React.lazy(() =>
  import("./Admin/Admin_Pages/Admin_Manage_Orders/Admin_Manage_Orders")
);

const HomeLazy = React.lazy(() => import("./Pages/Home/Home"));
const CartLazy = React.lazy(() => import("./Pages/Cart/Cart"));
const SearchLazy = React.lazy(() => import("./Pages/Search/Search"));

const FAQLazy = React.lazy(() => import("./Pages/FAQs/FAQs"));
const PrivacyPolicyLazy = React.lazy(() =>
  import("./Pages/PrivacyPolicy/PrivacyPolicy")
);
const Return_RefundLazy = React.lazy(() =>
  import("./Pages/Return_Refund/Return_Refund")
);

const AccountSettingsLazy = React.lazy(() =>
  import("./Pages/AccountSettings/AccountSettings")
);
const Admin_LoginLazy = React.lazy(() =>
  import("./Admin/Admin_Pages/Admin_Login/Admin_Login")
);
const LoadingLazy = React.lazy(() => import("./Components/Loading/Loading"));
const ProductDetailsLazy = React.lazy(() =>
  import("./Pages/ProductDetails/ProductDetails")
);

function App() {
  // Define routes
  const routes = [
    {
      path: "/",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <HomeLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/login",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <LoginLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/Search/:search",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <SearchLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/signup",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <SignupLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/cart",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <CartLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/FAQ",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <FAQLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/PrivacyPolicy",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <PrivacyPolicyLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/Return",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <Return_RefundLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/productDetails/:PID",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <ProductDetailsLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/accountsettings",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <AccountSettingsLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/admin_addproduct",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <Admin_AddProductLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/admin_manageOrders",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <Admin_manageOrders />
        </React.Suspense>
      ),
    },
    {
      path: "/Admin_MyStore",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <Admin_MyStoreLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/Admin_EditProduct/:PID",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <Admin_EditProductLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/Admin_Dashboard",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <Admin_DashboardLazy />
        </React.Suspense>
      ),
    },
    {
      path: "/admin_Login",
      element: (
        <React.Suspense fallback={<LoadingLazy active="true" />}>
          <Admin_LoginLazy />
        </React.Suspense>
      ),
    },
  ];

  // Create the router
  const router = createBrowserRouter(routes);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
